import React, { useState } from "react";
import AuthorCard from "./authorCard";
import "./style/allAuthors.scss";
import { ReadMore } from "../helperComponents/helpers";
const AllAuthors = ({ postData, authorData, postPrefix }) => {
  const [showAllAuthors, setShowAllAuthors] = useState(false);

  if (!postPrefix) {
    postPrefix = "blog";
  }

  const displayedAuthors = showAllAuthors
    ? authorData.nodes
    : authorData.nodes.slice(0, 10); // Mostrar solo los primeros 10 autores

  const remainingAuthors = authorData.nodes.length - displayedAuthors.length;

  const handleShowAllAuthors = () => {
    setShowAllAuthors(true);
  };
  const remainingText = remainingAuthors === 1 ? "article" : "articles";
  return (
    <>
    <div className="post-authors">
      {displayedAuthors.map((item, counter) => (
        <AuthorCard
          key={counter}
          postData={postData.nodes.filter(
            (post) => post.frontmatter.author === item.name
          ).length}
          postPrefix={postPrefix}
          authorData={item}
        />
      ))}
      {!showAllAuthors && (
        <div onClick={handleShowAllAuthors}>
          <ReadMore >
            Show {remainingAuthors} {remainingText}
          </ReadMore>
        </div>
      )}
    </div>
    </>
  );
};

export default AllAuthors;

