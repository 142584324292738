import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import glass from './img/magnify_glass.svg'
const AboutCard = ({ authorData, title }) => {
    return (
        <div className="about-card">
            <div className="card-top">
                <h3>CUSTOMER CASE STUDIES</h3>
                <h1>Education Case Studies</h1>
            </div>
            <div className="card-main">
                <p>
                    Explore how your peers, from startups to global enterprises, are protecting their customers using Securiwiser’s suite of products.
                </p>
            </div>
            <div className="input_container">

                <input type="text" placeholder="Search for educational orgs"/>
                <img src={glass}/>
            </div>
        </div>
    )
}

export default AboutCard