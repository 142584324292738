import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Authors from "../components/case-studies"
import SEO from "../components/layout/seo"

export const query = graphql`
query {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/content/posts/"}}
    ) {
      nodes {
        frontmatter {
          author
          category
          date(formatString: "MMMM DD, yyyy")
        }
      }
    }
    allAuthorsJson {
      nodes {
        ...authorFields
      }
    }    
}
`
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        description="Explore how your peers, from startups to global enterprises, are protecting their customers using Securiwiser’s suite of products."
        title="Education Case Studies | Securiwiser"
      />
      <Authors data={data} postPrefix='blog' />
    </Layout>
  )
}

export default IndexPage
