import React from "react"
import { ReadMore } from "../helperComponents/helpers.js"
import InLogo from "../../assets/images/in-logo.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import arrow from './img/arrow.svg'
import profile from './img/Ellipse 4.png'
import logo from  './img/logo.svg'
const AuthorCard = ({ postData, authorData, postPrefix, singleAuthor }) => {
    return (
        <div className="author-card">
            <a className="img_container" href="/shakhsiyah">
                <img src={logo} alt=""/>
            </a>
            <div className="info">
                <div className="education">
                    <a href="#">Education</a>
                    <p>5 MIN READ</p>
                </div>
                <div className="text">
                    <a href="/shakhsiyah">SHAKHSIYAH SCHOOLS</a>
                    <p>The Islamic Shakhsiyah Foundation is an educational establishment aimed at...</p>
                </div>
            </div>
            <div className="footer">
                <img src={profile} className="profile"/>
                <div>
                    <h3>Chief information officer</h3>
                    <ReadMore>
                    <a href="/shakhsiyah">Read case study</a>
                    </ReadMore>
                </div>
            </div>
        </div>
    )
}

export default AuthorCard